.main[data-v-0afe0b24] {
  width: 100%;
  height: calc(100vh);
  background-color: #0e2241;
  color: #fff;
}
.main .main-card[data-v-0afe0b24] {
    background-color: #0e2241;
    height: calc(100vh - 150px);
}
.main .main-card .deal-content[data-v-0afe0b24] {
      overflow-y: auto;
      width: 100%;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
}
.main .main-card .deal-content .deal-form[data-v-0afe0b24] {
        border: 1px solid black;
        width: 100%;
        height: calc(100% - 60px);
        overflow-y: auto;
}
.main .main-card .deal-content .deal-btns[data-v-0afe0b24] {
        position: fixed;
        bottom: 0;
        margin-top: 10px;
        width: 100%;
        height: 50px !important;
        line-height: 50px !important;
        text-align: center;
        background-color: #fff;
        border: 1px solid transparent;
}
.main .main-card .deal-content .deal-btns .deal-btn-item[data-v-0afe0b24] {
          height: 32px;
          padding: 4px 14px;
          margin-left: 20px;
}
.main .main-card .deal-content .deal-tools[data-v-0afe0b24] {
        position: absolute;
        bottom: 39px;
        left: 50px;
        z-index: 2;
        padding: 10px 0;
}
.main .main-card .deal-content .deal-flow[data-v-0afe0b24] {
        width: 100%;
        height: 100%;
        outline: none;
}
.main .main-card .deal-logs[data-v-0afe0b24] {
      color: black;
      width: 100%;
      height: 100%;
      padding: 10px 14px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      background-color: #fff;
      border-radius: 3px;
}
.main .main-header[data-v-0afe0b24] {
    height: 13.98148vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.main .main-content[data-v-0afe0b24] {
    height: 100%;
    margin-left: 10px;
    margin-right: 10px;
}
